<template>
  <div class="foreveryone__container">
    <div class="foreveryone__title">
      <h3 class="foreveryone__title-h3"><b>{{titleForeveryone.toUpperCase()}}</b></h3>
      <span class="bottom__hr"/>
    </div>
    <div class="foreveryone__body">
      <div
        class="foreveryone__body-item"
        v-for="item in data"
        :key="item.id"
      >
        <div class="foreveryone__center-item__icon">
          <span class="circle">
            <mdicon
              :name="item.icon"
              :width="50"
              :height="50"
            />
          </span>
        </div>
        <div class="foreveryone__body-item__title">
          {{ item.title }}
        </div>
        <div class="foreveryone__body-item__description">
          {{ item.subTitle }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data: () => ({
    titleForeveryone: 'доступно для всех водителей',
    data: [
      {
        id: 1,
        title: 'сертифицированный парк',
        subTitle: 'наш парк проходит ежеквартальную сертификацию Яндек.Такси',
        icon: 'fileDocumentCheckOutline'
      },
      {
        id: 2,
        title: 'круглосуточная техподдержка',
        subTitle: 'наши специалисты всегда на связи и готовы помочь решать возникающие вопросы 24/7',
        icon: 'faceAgent'
      },
      {
        id: 3,
        title: "выплаты 24 часа, \n 365 дней в году",
        subTitle: 'моментальные выплаты на карты любых банков в любое время дня и ночи, через наше приложение',
        icon: 'cashMultiple'
      }
    ]
  })
}
</script>

<style scoped>
*, *:before, *:after {
  box-sizing: border-box;
  white-space: pre-line;
  font-family: 'Comfortaa', serif;
}

.foreveryone__container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 1000px;
  padding: 10px 100px 0 100px;
  color: white;
}

.foreveryone__title {
  display: flex;
  justify-content: flex-end;
  font-size: 2em;
  color: #FFA31A;
}

.bottom__hr {
  z-index: -1;
  border-bottom: 10px solid #FFA31A;
  width: 450px;
  position: absolute;
  padding: 12px;
}

.foreveryone__body {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.icon__blocks {
  color: white;
  width: 50px;
  height: 50px;
}

.foreveryone__body-item {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-top: 150px;
  height: 300px;
  width: 400px;
}

.foreveryone__body-item__title {
  font-size: 1.3em;
  text-align: center;
}

.foreveryone__body-item__description {
  text-align: center;
  opacity: 0.9;
  font-size: 1em;
}

.circle {
  height: 150px;
  width: 150px;
  border: 1px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.foreveryone__title-h3 {
  padding: 0 0 100px 0;
}

@media (min-width: 1400px) {
  .foreveryone__body-item__title {
    font-size: 2em;
    padding: 30px 0;
  }
  .foreveryone__body-item__description {
    font-size: 1.4em;
  }
}

@media (max-width: 1100px) {
  .foreveryone__container {
    height: 100%;
  }

}

@media (max-width: 800px) {
  .foreveryone__container {
    padding: 200px 30px;
  }
  .foreveryone__title {
    text-align: center;
  }
}

@media (max-width: 425px) {
  .foreveryone__title {
    font-size: 1.4em;
  }
}
</style>
