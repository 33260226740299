import { createApp } from 'vue'
import App from './App.vue'
import VueSelect from 'vue-select'
import router from './router'
import store from './store'
import AosVue from 'aos-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VueCollapsiblePanel from '@dafcoe/vue-collapsible-panel'
import '@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css'
import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'
import VueTheMask from 'vue-the-mask'

createApp(App)
  .component('v-select', VueSelect)
  .use(store)
  .use(VueTheMask)
  .use(router)
  .use(AosVue)
  .use(mdiVue, {
    icons: mdijs
  })
  .use(VueCollapsiblePanel)
  .use(VueSweetalert2)
  .mount('#app')
