import axios from 'axios'

const instance = axios.create({
  baseURL: 'https://taxi-pobeda.ru/api/',
  withCredentials: true,
  headers: {
    accept: 'application/json'
  }
})

export default instance
