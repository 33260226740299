<template>
  <nav-menu :class="navScrolling ? 'nav-menu_scrolling': '' "></nav-menu>
  <router-view/>
</template>

<script>
import NavMenu from '@/components/navigation-menu'

export default {
  components: {
    NavMenu
  },
  data () {
    return {
      navScrolling: false
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      if (window.scrollY > 100) {
        this.navScrolling = true
      } else {
        this.navScrolling = false
      }
    }
  }
}
</script>

<style>

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}

.nav-menu_scrolling {
  background-color: rgba(0,0,0,0.7);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
</style>
