<template>
  <div class="container">
    <mainFormTaxi/>
    <div class="main__row row" id="top"/>
      <div class="container__info">
        <div class="privileges__row row">
          <privileges-blocks/>
        </div>
      </div>
    <div class="foreveryone__row">
      <foreveryone-blocks/>
    </div>
    <div class="howtowork__row row">
      <how-to-work-blocks/>
    </div>
    <div class="workforyou__row row">
      <work-for-you-blocks/>
    </div>
    <div class="accordion__row row">
      <accordion-blocks/>
    </div>
  </div>
</template>

<script>
import mainFormTaxi from '@/components/main-form-taxi'
import privilegesBlocks from '@/components/privileges-block/privileges-blocks'
import foreveryoneBlocks from '@/components/foreveryone-block/foreveryone-blocks'
import howToWorkBlocks from '@/components/howToWork-block/howToWork-blocks'
import workForYouBlocks from '@/components/workforyou-block/workforyou-blocks'
import accordionBlocks from '@/components/accordion-block/accordion-blocks'

export default {
  components: {
    mainFormTaxi,
    privilegesBlocks,
    foreveryoneBlocks,
    howToWorkBlocks,
    workForYouBlocks,
    accordionBlocks
  },
  data () {
    return {
    }
  },
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa&display=swap");
.container__info {
  padding: 0 100px 0 100px;
}

.main__row {
  height: 100vh;
  background-image: url('/src/assets/main-background.jpg');
  background-position: center;
  background-size: cover;
  width: 100% !important;
}

.privileges__row {
  background: url('/src/assets/about-us.svg') bottom left no-repeat;
  background-size: 850px;
}

.main__row:before {
  content: '';
  display: block;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:rgba(0, 0, 0, .4);
}

.howtowork__row {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.99);
}

.foreveryone__row {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.3 ), rgba(0, 0, 0, 0.7)), url('/src/assets/foreveryone-background.jpg') top center/ cover no-repeat;
  width: 100% !important;
}

.workforyou__row {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url('/src/assets/workforyou-background.jpg') top center/ cover no-repeat;
  width: 100% !important;
  box-shadow: inset 0 30px 30px rgba(0,0,0,0.9), inset 0 -30px 30px rgba(0,0,0,0.9);
}

.accordion__row {
  height: 800px;
}
@media (max-width: 1326px) {
  .privileges__row {
    background: none;
    background-size: 1250px;
  }

  .privileges__title {
    justify-content: center;
  }
}

@media screen and (max-width: 1024px),
       screen and (max-height: 1366px) {
  .main__row {
    height: 1366px;
  }

  .main__row:before {
    height: 1366px;
  }
}
@media screen and (max-width: 768px),
       screen and (max-height: 1024px) {
  .main__row {
    height: 1024px;
  }

  .main__row:before {
    height: 1024px;
  }
}
@media (max-width: 414px) {
  .main__row {
    height: 736px;
  }

  .main__row:before {
    height: 736px;
  }
}
@media (max-width: 411px) {
  .main__row {
    height: 731px;
  }

  .main__row:before {
    height: 731px;
  }
}

@media (max-width: 375px) {
  .main__row {
    height: 667px;
  }

  .main__row:before {
    height: 667px;
  }
}
@media screen and (max-width: 375px),
       screen and (max-height: 812px) {
  .main__row {
    height: 812px;
  }

  .container__info {
    padding: 0;
  }

  .main__row:before {
    height: 812px;
  }
}
@media (max-width: 360px) {
  .main__row {
    height: 650px;
  }

  .main__row:before {
    height: 650px;
  }
}

@media (max-width: 320px) {
  .main__row {
      height: 568px;
  }

  .main__row:before {
    height: 568px;
  }
}

</style>
