<template>
  <div class="howtowork__container">
    <div class="howtowork__container-title">
      <h3 class="howtowork__tilte">{{ howToWorkTitle }}</h3>
      <span class="bottom__hr"/>
    </div>
    <div class="howtowork__container-body">
      <div
        class="howtowork__item"
        v-for="item in data"
        :key="item.id"
      >
        <div class="howtowork__item-header">
          <img class="howtowork__item-image" :src="item.img"/>
        </div>
        <div class="howtowork__item-body">
          <div class="howtowork__item-body__header">0{{ item.id }}</div>
          <div class="howtowork__item-body__hr"/>
          <div class="howtowork__item-body__footer">{{ item.title }}</div>
        </div>
      </div>
    </div>
      <div class="howtowork__footer">
        <a href="#top" class="send-data__btn" type="button">отправить данные для регистрации</a>
      </div>
    </div>
</template>

<script>
export default {
  components: {
  },
  data: () => ({
    howToWorkTitle: 'как начать работать',
    data: [
      {
        id: 1,
        title: 'заполнить форму',
        img: require('@/assets/howToWork/one.png')
      },
      {
        id: 2,
        title: 'мы позвоним вам, чтобы уточнить детали',
        img: require('@/assets/howToWork/two.png')
      },
      {
        id: 3,
        title: 'оформитесь',
        img: require('@/assets/howToWork/three.png')
      },
      {
        id: 4,
        title: 'выходите на линию и начинайте зарабатывать',
        img: require('@/assets/howToWork/four.png')
      }
    ]
  })
}
</script>

<style scoped>
*, *:before, *:after {
  box-sizing: border-box;
}

.howtowork__container {
  padding: 50px 100px;
  margin: 0 auto;
  color: white;
}

.howtowork__tilte {
  color: #FFA31A;
  font-family: 'Comfortaa', serif;
  padding: 50px 0 50px 0;
}

.howtowork__block-left {
  display: flex;
}

.howtowork__block-left__step {
  color: #FFA31A;
  text-transform: uppercase;
  font-size: 1.7em;
  width: 150px;
}

.howtowork__block-right__title {
  text-transform: uppercase;
  font-size: 2em;
  font-weight: bold;
}

.howtowork__row {
  height: 350px;
  border-radius: 25px;
  width: 600px;
}

.block-img {
  display: flex;
}

.img__left {
  height: 450px;
}

.img__right {
  height: 450px;
  transform: scaleX(-1);
}

.one {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6 ), rgba(0, 0, 0, 0.7)), url('/src/assets/howToWork/one.png') top center/ cover no-repeat;
}

.two {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6 ), rgba(0, 0, 0, 0.7)), url('/src/assets/howToWork/two.png') top center/ cover no-repeat;
}

.three {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6 ), rgba(0, 0, 0, 0.7)), url('/src/assets/howToWork/three.png') top center/ cover no-repeat;
}

.four {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6 ), rgba(0, 0, 0, 0.7)), url('/src/assets/howToWork/four.png') top center/ cover no-repeat;
}

.howtowork__container-title {
  display: flex;
  justify-content: center;
  font-size: 2em;
}

.bottom__hr {
  z-index: -1;
  border-bottom: 10px solid #FFA31A;
  width: 400px;
  position: absolute;
  padding: 12px;
}

.howtowork__container-body {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  width: 70%;
  border-top: 2px solid #FFA31A;
  padding: 50px 0 0 0;
}

.howtowork__block-right__last{
  display: flex;
  justify-content: flex-end;
}

.howtowork__footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.send-data__btn {
  margin: 150px 0 0 0;
  padding: 20px;
  border: 1px solid #FFA31A;
  color: #FFA31A;
  cursor: pointer;
  font-size: 1.5em;
  border-radius: 50px;
  width: 700px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  transition: 1s; /* Время эффекта */
  font-family: 'Comfortaa', serif;
}

.send-data__btn:hover {
  background: #FF9E1AC1;
  color: #000000;
  transform: scale(1.02); /* Увеличиваем масштаб */
}

.send-data__btn:active, :focus {
  outline: 0;
  outline-offset: 0;
}

.howtowork__item {
  height: 100%;
  width: 35rem;
  border-radius: 50px;
  box-shadow: 0 0 50px rgba(255, 255, 255, 0.2);
  margin: 20px 0;
}

.howtowork__item-header {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 400px;
}

.howtowork__item-image {
  object-fit: contain;
  width: 90%;
  height: 100%;
}

.howtowork__item-body__hr {
  border-bottom: 3px solid #262222;
  padding: 10px 0;
  width: 90%;
}

.howtowork__item-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  font-size: 3em;
  font-family: 'Comfortaa', serif;
}

.howtowork__item-body__footer {
  padding: 20px 0;
  font-size: 0.6em;
  text-align: center;
}

.howtowork__container-body {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}
</style>
