<template>
  <div class="privileges__container">

    <div class="privileges__body">
      <div class="privileges__title">
      <p><b>{{ titlePrivileges.toUpperCase() }}</b></p>
      <span class="bottom__hr"/>
    </div>
      <div class="privileges__body-item">
        <div class="privileges__body-item__icon">
          <alarm
            class="alarm-icon"
            width="50px"
            height="50px"
          />
        </div>
        <div class="privileges__body-item__title">
          быстрый старт
        </div>
        <div class="privileges__body-item__description">
          от заявки до первого клиента - менее одного часа
        </div>
      </div>
      <div class="privileges__body-item">
        <div class="privileges__body-item__icon">
          <credit-card
            class="alarm-icon"
            width="50px"
            height="50px"
          />
        </div>
        <div class="privileges__body-item__title">
          деньги — сразу
        </div>
        <div class="privileges__body-item__description">
          оплату за наличные заказы вы получите в первый день работы
        </div>
      </div>
      <div class="privileges__body-item">
        <div class="privileges__body-item__icon">
          <calendar
            class="alarm-icon"
            width="50px"
            height="50px"
          />
        </div>
        <div class="privileges__body-item__title">
          удобный график
        </div>
        <div class="privileges__body-item__description">
          работайте несколько часов в день или полную смену
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Alarm from 'vue-material-design-icons-size/Alarm.vue'
import CreditCard from 'vue-material-design-icons-size/CreditCard.vue'
import Calendar from 'vue-material-design-icons-size/Calendar.vue'

export default {
  components: {
    Alarm,
    CreditCard,
    Calendar
  },
  data: () => ({
    titlePrivileges: 'c нами удобно и выгодно'
  })
}
</script>

<style scoped>
*, *:before, *:after {
  box-sizing: border-box;
  font-family: 'Roboto', serif;
}

.privileges__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0px 0 200px 0;
}

.privileges__title {
  display: flex;
  justify-content: flex-end;
  font-size: 2em;
}

.bottom__hr {
  z-index: -1;
  border-bottom: 10px solid #FFA31A;
  width: 450px;
  position: absolute;
  padding: 12px;
}

.privileges__body {
  display: flex;
  justify-content: space-around ;
  flex-wrap: wrap;
  padding: 100px 0 350px 0;
}

.alarm-icon {
  color: black;
}

.privileges__body-item {
  display: flex;
  padding: 100px 0px 0px 0px;
  flex-direction: column;
  justify-content: space-around;
  height: 225px;
  width: 400px;
}

.privileges__body-item__title {
  font-size: 1.8em;
}

.privileges__body-item__description {
  font-size: 1.4em;
  opacity: 0.7;
}

@media (max-width: 1326px) {
  .privileges__container {
    padding: 100px 0;
  }
  .privileges__body {
    padding: 100px 0;
  }
}

@media (max-width: 1024px) {
  .privileges__title {
    font-size: 2.2em;
    justify-content: center;
  }

  .privileges__body-item {
    padding: 150px 0;
  }

  .bottom__hr {
    padding: 14px;
    width: 100%;
  }

  .privileges__body-item {
    font-size: 1em;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #8C8C8C;
  }
}

@media (max-width: 768px) {
  .privileges__title {
    font-size: 2em;
    justify-content: center;
  }

  .bottom__hr {
    padding: 12px;
    width: 100%;
  }

  .privileges__body-item {
    font-size: 1em;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #8C8C8C;
  }
}

@media (max-width: 414px) {
  .privileges__title {
    font-size: 1.5em;
    justify-content: center;
  }

  .bottom__hr {
    padding: 10px;
    width: 100%;
  }

  .privileges__body-item {
    font-size: 1em;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #8C8C8C;
  }
}

@media (max-width: 360px) {
  .privileges__title {
    font-size: 1.5em;
    justify-content: center;
  }

  .bottom__hr {
    padding: 10px;
    width: 100%;
  }

  .privileges__body-item {
    font-size: 1em;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #8C8C8C;
  }
}

@media (max-width: 320px) {
  .privileges__title {
    font-size: 1.4em;
    justify-content: center;
  }

  .bottom__hr {
    padding: 8px;
    width: 100%;
  }

  .privileges__body {
    padding: 50px 0;
  }

  .privileges__body-item {
    font-size: 0.7em;
    height: 150px;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #8C8C8C;
  }
}

@media (max-width: 280px) {
  .privileges__title {
    font-size: 1.2em;
    justify-content: center;
  }

  .bottom__hr {
    padding: 6px;
    width: 100%;
  }

  .privileges__body {
    padding: 0;
  }

  .privileges__body-item {
    font-size: 0.7em;
    height: 150px;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #8C8C8C;
  }
}
</style>
