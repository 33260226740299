<template>
  <div class="workforyou__container">
    <div class="workforyou__head">
      <div class="workforyou__head-title">{{ titleWorkForYou }}</div>
      <div class="workforyou__head-sub-title">{{ subTitleWorkForYou }}</div>
    </div>
    <div class="workforyou__body">
      <div class="workforyou__body-seo">
        <a href="https://t.me/AndreyPrutkov9"><img class="navbar-icon" src="@/assets/seo/telegram-icon.png" alt=""></a>
        <a href="https://wa.me/79920252599"><img class="navbar-icon" src="@/assets/seo/whatsapp-icon.png" alt=""></a>
        <a href="https://viber.click/79920252599"><img class="navbar-icon" src="@/assets/seo/viber-icon.png" alt=""></a>
        <div class="workforyou__body-seo-phone-number">
          +7(992)-025-25-99
        </div>
      </div>
      <div class="workforyou__body-info">
        {{ infoBody }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    titleWorkForYou: 'Мы работаем для Вас круглосуточно и ежедневно!',
    subTitleWorkForYou: 'Напишите или позвоните нам, мы ответим на все Ваши вопросы и быстро подключим Вас к нашему парку',
    infoBody: 'Курглосуточная техподдержка и регистрация водителей'
  })
}
</script>

<style scoped>
*, *:before, *:after {
  box-sizing: border-box;
  font-family: 'Comfortaa', serif;
}

.workforyou__container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
  color: white;
}

.workforyou__head {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  width: 100%;
}

.workforyou__head-title {
  font-size: 2em;
  padding: 600px 0px 30px 0px;
}

.workforyou__head-sub-title {
  font-size: 1.2em;
  width: 700px;
  padding: 0 0 60px 0;
}

.workforyou__body {
  display: flex;
  flex-direction: column;
}

.workforyou__body-seo {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 2em;
  flex-basis: auto;
  align-items: center;
}

.workforyou__body-info {
  padding: 30px 0px;
  font-size: 1.6em;
  width: 600px;
  text-align: center;
  color: #FFA31A;
}

.navbar-icon {
  width: 50px;
  height: 50px;
}

@media (max-width: 800px) {
  .workforyou__body-info,
  .workforyou__head-sub-title {
    width: 100%;
  }
  .workforyou__container {
    height: 100%;
  }
  .workforyou__body-seo-phone-number,
  .workforyou__body-info {
    font-size: 0.7em;
  }
  .workforyou__head-sub-title {
    font-size: 1em;
  }
}

@media (max-width: 360px) {
  .workforyou__head,
  .workforyou__body {
    width: 70%;
  }
  .workforyou__head-title {
    font-size: 1.4em;
  }
  .workforyou__head-sub-title {
    font-size: 1em;
  }
  .workforyou__body-seo {
    display: block;
    text-align: center;
  }
}
</style>
