<template>
  <div class="accordion__container">
    <div class="accordion__container-title">
      <p><b>{{ accordionTitle.toUpperCase() }}</b></p>
      <span class="bottom__hr"/>
    </div>
    <div class="accordion__body">
      <vue-collapsible-panel-group base-color="#FFFFFF">
        <vue-collapsible-panel
          :expanded="false"
          class="accordion__item"
          v-for="item in content"
          :key="item.id"
        >
        <template #title >
          <h2 class="accordion__title">{{ item.title }}</h2>
        </template>
        <template #content>
          <div class="accordion__sub-title">
            {{ item.description }}
          </div>
        </template>
      </vue-collapsible-panel>
    </vue-collapsible-panel-group>
    </div>
    <div class="howtowork__footer">
      <a href="#top" class="send-data__btn" type="button">подключиться к нам</a>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    accordionTitle: 'ответы на вопросы',
    content: [
      {
        id: 1,
        title: 'Каким может быть доход?',
        description: 'Финальный заработок водителя зависит от региона и от графика работы. С Яндекс.Такси можно как подрабатывать всего несколько часов в неделю, так и выполнять заказы каждый день. И для водителей в сервисе есть свои бонусы. С доходом всё прозрачно. В разделе «Заработок» в Таксометре можно проверить список всех поездок и детальную информацию по каждой: маршрут, стоимость, бонусы. Пассажиры будут платить вам как наличными, так и картой. И порой оставлять чаевые :)'
      },
      {
        id: 2,
        title: 'Что нужно чтобы получать заказы от Яндекс.Такси и UBER',
        description: 'Начать работу водителем с сервисом Яндекс.Такси очень просто. Вам понадобится смартфон с операционной системой Android 5.0, камерой, GPS-модулем и 2 ГБ оперативной памяти. Скачайте и установите приложение Таксометр или UBER Driver RUSSIA. Оно будет предлагать вам заказы, прокладывать маршрут, рассчитывать цену поездки и держать в курсе всех новостей сервиса. Вам не придётся искать себе пассажиров или думать о том, как лучше проехать — можно сосредоточиться на заказах.'
      },
      {
        id: 3,
        title: 'Я не проффесиональный водитель- смогу ли я Работать в такси ?',
        description: 'Выполнять заказы Яндекс.Такси могут водители старше 21 года, со стажем вождения от трёх лет и водительским удостоверением, выданным в РФ, Беларуси, Кыргызстане, Казахстане, Абхазии и Южной Осетии. Новичкам мы расскажем обо всех особенностях и стандартах качества нашего сервиса. Во всём остальном поможет Таксометр: найдёт заказ и проложит маршрут c учётом пробок и перекрытий.'
      },
      {
        id: 4,
        title: 'Как распределяются заказы между водителями?',
        description: 'Система заказов Яндекс.Такси распределяет заказы автоматически. Она ищет ближайшую к пассажиру машину, которая подходит по тарифу и дополнительным требованиям, если такие есть. Чтобы система предлагала вам больше заказов, можете получить корону в профиле Таксометра. Она есть, например, у водителей, которые выполняют заказы на автомобилях, оклеенных символикой Яндекс.Такси.'
      },
      {
        id: 5,
        title: 'Работа водителем такси - это безопасно?',
        description: 'Наша служба поддержки всегда на связи с вами, 24/7. Обратиться за помощью можно по телефону или прямо через Таксометр — приложение для получения заказов. К тому же жизнь и здоровье водителя и пассажиров застрахованы на время поездки.'
      },
      {
        id: 6,
        title: 'Когда доступен вывод денег?',
        description: 'Вывод доступен круглосуточно, без праздников и выходных'
      },
      {
        id: 7,
        title: 'Как сменить автомобиль?',
        description: 'Напишите нам в Техподдержку , пришлите фото СТС и в течении 5 минут мы сменим автомобиль в Вашем профиле.'
      }]
  })
}
</script>
<style scoped>
*, *:before, *:after {
  box-sizing: border-box;
  font-family: 'Comfortaa', serif;
}

.accordion__container {
  display: flex;
  flex-direction: column;
  jusctify-content: center;
  align-items: center;
}

.accordion__container-title {
  display: flex;
  justify-content: center;
  font-size: 2em;
  padding: 100px 0px 50px 0px;
  color: black;
}

.bottom__hr {
  z-index: -1;
  border-bottom: 10px solid #FFA31A;
  width: 400px;
  position: absolute;
  padding: 12px;
}

.accordion__body {
  width: 60%;
}

.accordion__item {
  padding: 20px 0;
}

.howtowork__footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.send-data__btn {
  margin: 50px 0 50px 0;
  padding: 20px;
  background: #FFA31A;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  border-radius: 50px;
  width: 450px;
  font-weight: bold;
  color: white;
  text-decoration: none;
  text-align: center;
}

.send-data__btn:hover {
  background: #FF9E1AC1;
}

.send-data__btn:active, :focus {
  outline: 0;
  outline-offset: 0;
}

@media (max-width: 800px) {
  .accordion__item {
    padding: 40px 0;
  }
}

@media (max-width: 600px) {
  .accordion__item {
    padding: 50px 0;
  }
  .bottom__hr {
    width: 100%;
  }

  .send-data__btn {
    width: 100%;
  }
}

@media (max-width: 450px) {
  .accordion__item {
    padding: 60px 0;
  }
  .accordion__sub-title {
    padding: 50px 0;
  }
  .accordion__title {
    font-size: 1em;
  }
}

@media (max-width: 360px) {
  .accordion__container-title {
    font-size: 1.3em;
  }
}
</style>
