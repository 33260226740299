<template>
  <div class="main__form-container">
    <div class="main__form-block" v-if="sendData === false">
      <div class="form-block__items">
        <div class="form-block__header">
          <div class="form-block__header-left">Подключение к</div>
          <div class="form-block__header-right">
            <p class="header-right__info">Яндекс.Такси</p>
          </div>
        </div>
        <div class="form-block__body">
          <div class="form-block__body-hr" />
          <div class="form-block__body-info">
            Мы свяжемся с Вами и зарегистрируем в Яндекс.Такси на лучших условиях
          </div>
          <div v-if="errors">
            <p style="color: red" v-for="err in errors" :key="err">{{err.info}}</p>
          </div>
        </div>
        <div class="form-block__footer">
          <div class="form-block__footer-content">
            <form @submit.prevent action="" class="footer__form">
              <div class="footer__form-input">
                <input type="text" placeholder="Ваша почта" v-model="mail">
                <input type="text" placeholder="Имя" v-model="form.first_name">
                <input type="text" v-mask="'(###)-###-##-##'"  placeholder="Телефон (999)-999-99-99" v-model="form.phone_number">
                <input type="text" placeholder="Ваш город" v-model="form.city">

                <v-select id="mySelect" class="selected_sposob"
                          :options="expOptions"
                          label="title"
                          v-model="selecExp"
                          placeholder="Стаж"
                />
<!--                <v-select id="mySelect" class="selected_sposob"-->
<!--                          :options="options"-->
<!--                          label="title"-->
<!--                          v-model="selectOption"-->
<!--                          placeholder="Способ доставки"-->
<!--                />-->
              </div>
              <div class="footer__row">
                <div class="footer__form-right">
                  <input class="footer__form-checkbox" type="checkbox" v-model="checkPolictics">
                  <p class="footer__form-text">Я полностью принимаю условия оферты и даю согласие на обработку моих персональных данных в соответствии с условиями, указанными в политике конфиденциальности</p>
                </div>
                <button class="footer__row-btn" v-on:click="sendMessage">Оставить заявку</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import instance from '../api/instance'

export default {
  name: 'mainFormTaxi',
  data () {
    return {
      sendData: false,
      form: {
        first_name: '',
        phone_number: '',
        city: ''
      },
      mail: '',
      checkPolictics: false,
      selectOption: '',
      errors: [],
      options: [
        { title: 'Авто' },
        { title: 'Пешком' },
        { title: 'Велосипед' }
      ],
      selecExp: '',
      expOptions: [
        { title: 'Менее 1 года' },
        { title: 'Более 1 года' },
        { title: 'Менее 5 лет' },
        { title: 'Более 5 лет' }
      ]
    }
  },
  methods: {
    showAlert () {
      this.$swal.fire({
        icon: 'success',
        timer: 2000,
        showConfirmButton: false,
        title: 'Успешная отправка заявки',
        color: '#000'
      })
    },
    sendMessage () {
      this.errors = []
      if (this.mail === '') {
        this.errors.push({'info': '* Укажите Вашу почту!', key: 'mail'})
      } else {
        this.errors.filter(e => e.key === 'mail')
      }
      if (this.form.first_name === '') {
        this.errors.push({'info': '* Укажите Ваше Имя!', key: 'first_name'})
      } else {
        this.errors.filter(e => e.key === 'first_name')
      }
      if (this.form.phone_number === '') {
        this.errors.push({'info': '* Укажите Ваш телефон!', key: 'phone_number'})
      } else {
        this.errors.filter(e => e.key === 'phone_number')
      }
      if (this.form.city === '') {
        this.errors.push({'info': '* Укажите Ваш город!', key: 'city'})
      } else {
        this.errors.filter(e => e.key === 'city')
      }
      if (this.selecExp === '') {
        this.errors.push({'info': '* Укажите Ваш стаж!', key: 'exp'})
      } else {
        this.errors.filter(e => e.key === 'exp')
      }
      if (this.checkPolictics === false) {
        this.errors.push({'info': '* Подтвердите соглашение с политикой конфиденциальности!!', key: 'conf'})
      } else {
        this.errors.filter(e => e.key === 'conf')
      }
      if (this.checkPolictics && ((this.mail && this.form.first_name && this.form.city && this.form.phone_number && this.selecExp) !== '') && this.checkPolictics !== false) {
        instance.post('email/send_email', {
          email: [
            'usplys@mail.ru'
          ],
          body: {
            mail: this.mail,
            first_name: this.form.first_name,
            phone_number: this.form.phone_number,
            city: this.form.city,
            exp: this.selecExp.title
          }
        })
          .then(response => {
            this.mail = ''
            this.sendData = true
            this.showAlert()
            this.form.first_name = ''
            this.form.phone_number = ''
            this.form.city = ''
            this.selecExp = ''
            this.checkPolictics = false
            // eslint-disable-next-line no-return-assign
            setTimeout(() => this.sendData = false, 2000)
          })
      }
    }
  }
}
</script>

<style scoped>
@import "vue-select/dist/vue-select.css";
#mySelect .v-select .dropdown-toggle {
   border: none;
}
*, *:before, *:after {
  box-sizing: border-box;
  font-family: 'Roboto', serif;
}

.main__form-container {
  z-index: 999999;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width:100%;
  height: 100%;
}

.main__form-block {
  width: 950px;
  height: 550px;
  border-radius: 25px;
  background: #fff;
  background-color:rgba(0, 0, 0, 0.4);
}

.form-block__items {
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
}

.form-block__header {
  display: flex;
  justify-content: center;
  font-size: 2.2em;
}

.form-block__header-right {
  display: flex;
  font-weight: bold;
  padding: 0px 0px 0px 10px;
}

.header-right__info:first-letter {
  color: red;
}

.form-block__body {
    padding: 20px 50px 10px 50px;
}

.form-block__body-hr {
  border-bottom: 1px solid #FFA31A;
}

.form-block__body-info {
  font-size: 1.4em;
  padding: 30px 100px;
  text-align: center;
}

.form-block__footer {
  display: flex;
  justify-content: space-between;
  margin: 25px 0px;
}

.footer__form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 350px;
  width: 460px;
}

.footer__row-btn {
  margin: 0px 10px 52px 10px;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.footer__form-right {
  display: flex;
  padding: 10px 0px 0px 20px;
}

.footer__form-text {
  padding: 0px 0px 0px 10px;
}

.footer__form-checkbox {
  width: 60px;
  height: 60px;
  margin: -15px 0px 0px 0px;
}

.footer__row {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

input[type=text] {
  padding: 13px;
  margin: 7px 0;
  width: 100%;
  font-size: 1.2em;
  border-radius: 10px;
  border: 1px solid #615A5A;
  background: #615A5A;
  color: white;
}

.selected_sposob {
  margin: 5px 0;
  width: 100%;
  font-size: 1.2em;
  border-radius: 10px;
  border: 1px solid #615A5A;
  background: #615A5A;
}
>>> {
  --vs-controls-color: #615A5A;
  --vs-border-color: #615A5A;

  --vs-dropdown-bg: #FFFFFF;
  --vs-dropdown-color: #000;
  --vs-dropdown-option-color: #000;

  --vs-selected-bg: #615A5A;
  --vs-selected-color: #FFFFFF;
  --vs-font-size: 1.2rem;
  --vs-line-height: 1.4;
  --vs-search-input-color: #7e7777;

  --vs-dropdown-option--active-bg: #615A5A;
  --vs-dropdown-option--active-color: #FFFFFF;
}

input:active, :hover, :focus {
    outline: 0;
    outline-offset: 0;
}

.footer__row-btn {
  background: #FFA31A;
  font-size: 1.5em;
}

button:active, button:focus {
  outline: none;
}
button::-moz-focus-inner {
  border: 0px;
}

@media (max-width: 980px) {
  .main__form-block {
    width: 700px;
    height: 600px

  }
  .footer__form {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 150px;
  }
  .footer__form-input {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
  }
  .form-block__footer {
    margin: 0;
  }
  .footer__form-input {
    width: 100%;
  }
  .footer__form-right {
    margin: 15px 0;
  }
  .form-block__body {
    padding: 10px 25px 5px 25px;
  }
  .form-block__body-info {
    padding: 15px 50px;
  }
}

@media (max-width: 710px) {
  .main__form-block {
    width: 500px;
    height: 500px
  }
  .footer__form-text {
    font-size: 0.7em;
  }
  .form-block__header {
    font-size: 1.6em;
  }
  .form-block__body {
    padding: 10px 25px 5px 25px;
    font-size: 0.7em;
  }
}

@media (max-width: 510px) {
  .main__form-block {
    width: 300px;
    height: 400px
  }
  .footer__form-text {
    font-size: 0.5em;
  }
  .form-block__header {
    font-size: 1em;
  }
  .form-block__body {
    font-size: 0.4em;
  }
  .form-block__body-info {
    display: none;
  }
  .footer__row-btn {
    margin: 0;
    padding: 10px;
    font-size: 1.2em;
  }
  .footer__row {
    height: 0;
  }
}

@media (max-width: 415px) {
  .main__form-block {
    width: 370px;
    height: 500px
  }
  .footer__form-text {
    font-size: 0.6em;
  }
  .form-block__header {
    font-size: 1.2em;
  }
  .form-block__body {
    font-size: 0.6em;
  }
  .form-block__body-info {
    display: block;
    padding: 20px 0;
  }
  .footer__row-btn {
    margin: 0;
    padding: 10px;
    font-size: 1.2em;
  }
  .footer__row {
    height: 0;
  }
}

@media (max-width: 320px) {
  .main__form-block {
    width: 270px;
    height: 400px
  }
  .footer__form-text {
    font-size: 0.4em;
  }
  .form-block__header {
    font-size: 0.9em;
  }
  .form-block__body {
    font-size: 0.6em;
  }
  .form-block__body-info {
    display: none;
  }
  .footer__row-btn {
    margin: 0;
    padding: 10px;
    font-size: 1.2em;
  }
  .footer__row {
    height: 0;
  }
}

</style>

