<template>
  <nav class="navigation__menu">
    <div class="navigation__left">
      <div class="navigation__left-logo">
        {{ nameLogo }}
      </div>
      <div class="navigation__left-item">
          <nav :class="selected" class="navigation__section">
            <a class="navigation__section-item home" v-on:click="makeActive('home') && $emit('selected', 'home')">Яндекс.Такси</a>
<!--            <a class="navigation__section-item dostavka" v-on:click="makeActive('dostavka') && $emit('selected', 'dostavka')">Яндекс.Доставка</a>-->
          </nav>
      </div>
    </div>
    <div class="navigation__right">
      <div class="navigation__right-phone">
        <a href="tel:+79920252599" class="navigation__right-phone-mobile">+7(992)-025-25-99</a>
      </div>
      <div class="navigation__right-seo">
        <a href="https://t.me/AndreyPrutkov"><img class="navbar-icon" src="../assets/seo/telegram-icon.png" alt=""></a>
        <a href="https://wa.me/79920252599"><img class="navbar-icon" src="../assets/seo/whatsapp-icon.png" alt=""></a>
        <a href="https://viber.click/79920252599"><img class="navbar-icon" src="../assets/seo/viber-icon.png" alt=""></a>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  name: 'nav-menu',
  components: {
  },
  data () {
    return {
      nameLogo: 'победа',
      hasError: false,
      isActive: true,
      selected: 'home'
    }
  },
  methods: {
    makeActive: function (item) {
      this.selected = item
    }
  }
}
</script>

<style scoped>
* {
  font-family: 'Comfortaa', serif;
}

nav.home .home,
nav.dostavka .dostavka {
  background-color:rgba(255, 255, 255, 0.4);
}

.navigation__menu {
  z-index: 999999999;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  position: fixed;
  width: 100%;
}

.navbar-icon {
  height: 35px;
  width: 35px;
  margin-left: 30px;
}

.navigation__left {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 35px 0;
}

.whatsapp-icon {
  width: 35px;
  height: 35px;
}

.navigation__right {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  color: white;
}

.navigation__left-logo {
  color: #FFA31A;
  font-size: 2.5em;
  margin: 0 0 0 100px;
}

.navigation__left-item {
  margin: 0 0 0 100px;
}

.navigation__right-phone-mobile {
  text-decoration: none;
  color: white;
}

.navigation__right {
  padding: 0 100px;
}

.navigation__section {
  display: flex;
  text-decoration: none;
  color: #fff;
  list-style: none;
}

.navigation__section-item {
  margin: 5px 15px;
  padding: 8px 30px;
  border-radius: 25px;
  background: #fff;
  background-color:rgba(255, 255, 255, 0.13);
}

.navigation__section-item:hover, .selected{
  cursor: pointer;
  background: #fff;
  background-color:rgba(255, 255, 255, 0.25);
}

@media (max-width: 1770px) {
  .navigation__section-item {
    margin: 2px 5px;
    padding: 8px 15px;
  }

  .navigation__left-logo {
    margin: 0px 0px 0px 100px;
  }
}

@media (max-width: 1270px) {
  .navigation__left-item {
    display: none;
  }


  button:active, :hover, :focus {
    outline: 0;
    outline-offset: 0;
  }
}
@media (max-width: 1100px) {
  .navigation__left-item {
    display: none;
  }
  .navigation__left-logo {
    margin: 0px 0px 0px 50px;
  }



  button:active, :hover, :focus {
    outline: 0;
    outline-offset: 0;
  }
}
</style>
